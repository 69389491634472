<template>
  <v-container :class="clienteComponent ? 'px-3' : ''">
    <v-card :flat="clienteComponent ? true : false">
      <v-card-title
        v-if="!clienteComponent"
        :class="
          clienteComponent
            ? ' text-h5 font-weight-bold pa-3'
            : 'accent text-h5 font-weight-bold pa-3'
        "
      >
        <v-icon color="primary" left>mdi-toolbox-outline</v-icon>
        Atividades
        <v-spacer></v-spacer>
      </v-card-title>
      <v-divider v-if="!clienteComponent"></v-divider>
      <v-card-text>
        <v-card-title>
          <v-text-field
            style="max-width: 200px"
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            clearable
            dense
            hide-details
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-select
            style="max-width: 200px"
            v-model="selectedStatus"
            :items="statusSelection"
            outlined
            item-text="descricao"
            item-value="valor"
            dense
            hide-details
            label="Status"
            return-object
          >
          </v-select>
          <v-autocomplete
            style="max-width: 200px"
            v-model="selectedCliente"
            :items="clientes"
            :loading="loadingCliente"
            :search-input.sync="searchCliente"
            clearable
            @input="buscaCliente(selectedCliente, dataCalendario + '-01')"
            dense
            solo
            flat
            hide-details
            hide-no-data
            outlined
            class="mx-6 my-0"
            item-text="nome_fantasia"
            item-value="id"
            label="Clientes"
            prepend-inner-icon="mdi-account-group"
            return-object
          >
          </v-autocomplete>
          <v-card
            outlined
            class="d-flex mr-6 align-center"
            height="40px"
            style="overflow: hidden"
          >
            <v-btn
              @click="subMonth"
              color="white"
              tile
              depressed
              height="40px"
              class="px-1"
              min-width="40px"
            >
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <v-divider vertical class="ma-0"></v-divider>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="true"
              :return-value.sync="dataCalendario"
              transition="slide-x-reverse-transition"
              slide-y-transition
              offset-y
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" text>
                  {{ mostraMes }}
                  <v-icon right>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                v-model="dataCalendario"
                type="month"
                color="blue"
                no-title
                scrollable
                @change="$refs.menu.save(dataCalendario)"
              >
                <!-- <v-spacer></v-spacer>
            <v-btn text color="primary" @click="menu = false">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="$refs.menu.save(search)">
              OK
            </v-btn> -->
              </v-date-picker>
            </v-menu>
            <v-divider vertical class="ma-0"></v-divider>
            <v-btn
              @click="addMonth"
              color="white"
              tile
              depressed
              height="40px"
              class="px-1"
              min-width="40px"
            >
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </v-card>
          <v-btn
            color="primary"
            class="white--text"
            :disabled="!listaAtividades.length"
            @click="gerarpdf"
          >
            <!-- <v-icon left>mdi-file-pdf-box</v-icon> -->
            PDF
          </v-btn>
          <!-- <v-btn
            @click="addAtividade"
            align="center"
            color="secondary"
            class="white--text "
            :small="clienteComponent ? true : false"
          >
            adicionar
          </v-btn> -->
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :headers="headers"
            :search="search"
            :items="listaAtividades"
            :single-expand="true"
            :expanded.sync="expanded"
            show-expand
            item-key="id_atividade"
            :items-per-page="10"
            :sort-desc="true"
            class="data-tables data-tables__row-click"
            @click:row="editAtividade"
          >
            <template v-slot:item.atividade_inicio="{ item }">
              {{ item.atividade_inicio | dateFormat("dd/MM/yyyy") }}
            </template>
            <template v-slot:item.atividade_entrega="{ item }">
              {{ item.atividade_entrega | dateFormat("dd/MM/yyyy") }}
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headersColaborador.length + 6">
                <v-data-table
                  :headers="headersColaborador"
                  :items="item.colaborador"
                  :items-per-page="10"
                  :sort-desc="true"
                  class="data-tables data-tables__row-click"
                >
                  <template v-slot:item.inicio="{ item }">
                    {{ item.inicio | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                  </template>
                  <template v-slot:item.fim="{ item }">
                    {{ item.fim | dateFormat("dd/MM/yyyy HH:mm:ss") }}
                  </template>
                </v-data-table>
              </td>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card-text>

      <PdfDialogComp
        v-if="pdfDialog"
        :pdfDialog.sync="pdfDialog"
        :pdfData="pdfData"
        title="RELATÓRIO DURAÇÃO DAS ATIVIDADES"
      />
    </v-card>
  </v-container>
</template>

<script>
import { fetchAtividadesClientesGeral } from "@/api/gestor/relatorios/atividades_clientes.js";
const pdfGenerator = () =>
  import(/* webpackChunkName: "relatorio" */ "@/helpers/pdf/geral.js");
import {
  parseISO,
  format,
  addMonths,
  subMonths,
  // startOfMonth,
  // lastDayOfMonth,
} from "date-fns";
import { fetchClientes } from "@/api/gestor/clientes.js";
export default {
  name: "ListaAtividades",

  props: {
    cliente_id: {
      type: [Number, String],
    },
    clienteComponent: {
      type: Boolean,
      default: false,
    },
  },

  components: {
    // AtividadesNovoTrabalho: () =>
    //   import("./components/AtividadesNovoTrabalho.vue"),
    // AtividadesEditarTrabalho: () => import("./AtividadesEditarTrabalho.vue"),
    PdfDialogComp: () => import("@/components/PdfDialogComp.vue"),
  },

  data() {
    return {
      search: "",
      searchCliente: "",
      selectedCliente: null,
      dialogAddAtividade: false,
      dialogEditAtividade: false,
      loadingCliente: false,
      listaAtividades: [],
      dataCalendario: format(new Date(), "yyyy-MM"),
      edit: false,
      atividadeSelected: null,
      pdfData: null,
      pdfDialog: false,
      clientes: [],
      menu: false,
      mesAtual: format(new Date(), "yyyy-MM"),
      expanded: [],
      soma_totais: [],
      selectedStatus: {
        descricao: "Todos",
        valor: "",
      },
      statusSelection: [
        {
          descricao: "Todos",
          valor: "",
        },
        {
          descricao: "A Fazer",
          valor: 0,
        },
        {
          descricao: "Em andamento",
          valor: 1,
        },
        {
          descricao: "Em espera",
          valor: 2,
        },
        {
          descricao: "Concluído aguardando gestor",
          valor: 3,
        },
        {
          descricao: "Refazer - gestor",
          valor: 4,
        },
        {
          descricao: "Aprovado aguardando cliente",
          valor: 5,
        },
        {
          descricao: "Refazer - cliente",
          valor: 6,
        },
        {
          descricao: "Finalizado",
          valor: 7,
        },
        {
          descricao: "Atrasadas",
          valor: 8,
        },
      ],
    };
  },
  watch: {
    dataCalendario() {
      if (this.dataCalendario) {
        this.buscaCliente(this.selectedCliente, this.dataCalendario + "-01");
      }
    },

    selectedStatus() {
      if (this.selectedStatus) {
        this.buscaCliente(this.selectedCliente, this.dataCalendario + "-01");
      }
    },
  },

  computed: {
    headers() {
      return [
        {
          text: "Titulo",
          width: "200px",
          value: "titulo",
        },
        {
          text: "Cliente",
          width: "",
          value: "cliente_nome",
        },
        {
          text: "Serviço",
          width: "",
          value: "servico",
        },
        {
          text: "Inicio",
          width: "",
          value: "atividade_inicio",
        },
        {
          text: "Entrega",
          width: "",
          value: "atividade_entrega",
        },
        {
          text: "Tempo Total",
          width: "",
          value: "total",
        },
      ];
    },
    headersColaborador() {
      return [
        {
          text: "Nome Colaborador",

          value: "nome",
        },
        {
          text: "Inicio Atividade",

          value: "inicio",
        },
        {
          text: "Fim Atividade",

          value: "fim",
        },
        {
          text: "Total Trabalhado",

          value: "total",
        },
      ];
    },
    mostraMes() {
      let date = this.dataCalendario + "01";
      return format(parseISO(date), "MMM");
    },
  },

  methods: {
    addMonth() {
      this.dataCalendario = format(
        addMonths(parseISO(this.dataCalendario), 1),
        "yyyy-MM"
      );
    },
    subMonth() {
      this.dataCalendario = format(
        subMonths(parseISO(this.dataCalendario), 1),
        "yyyy-MM"
      );
    },
    buscaCliente(item1, item2) {
      console.log(this.selectedStatus.valor);

      if (item1 && item2) {
      this.listaAtividades = [];

  

      this.getAtividades(
        `?cliente_id=${item1.id}&data=${item2}${this.selectedStatus.valor !== '' && this.selectedStatus.valor >= 0 ? `&status=${this.selectedStatus.valor}` : ''}`
      );
      } else if (item1) {
      this.listaAtividades = [];
      this.getAtividades(
        `?cliente_id=${item1.id}${(this.selectedStatus.valor !== '' && this.selectedStatus.valor >= 0) ? `&status=${this.selectedStatus.valor}` : ''}`
      );
      } else if (item2) {
      this.listaAtividades = [];
      this.getAtividades(
        `?data=${item2}${this.selectedStatus.valor !== '' && this.selectedStatus.valor >= 0 ? `&status=${this.selectedStatus.valor}` : ''}`
      );
      }
    },
    getClientes() {
      this.loadingCliente = true;
      fetchClientes()
        .then((response) => {
          this.clientes = response;
          this.loadingCliente = false;
        })
        .catch(() => {
          this.loadingCliente = false;
        });
    },
    editAtividade(item) {
      this.atividadeSelected = { ...item };
      this.dialogEditAtividade = true;
    },
    zeroPad(num) {
      let str = String(num);
      if (str.length < 2) {
        return "0" + str;
      }

      return str;
    },

    addAtividade() {
      this.dialogAddAtividade = true;
    },

    getAtividades(filtro) {
      this.loading = true;
      return fetchAtividadesClientesGeral(filtro ? filtro : "")
        .then((response) => {
          this.listaAtividades = response.atividades_tempos;
          this.soma_totais = response.soma_totais;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    pdfContent() {
      let content = [];
      let count = 0;
      let objeto_cabecario = {
        margin: [0, 0, 0, 10],
        fontSize: 8,
        columns: [
          {
            text: "OLE DESIGNER & COMUNICAÇÃO",
            alignment: "left",
          },
          {
            text:
              "CRUZ E BETTO PRESTADORA DE SERVICOS EM DESIGN LTDA | CNPJ: 08.004.795/0001-18 | Telefone" +
              "(45) 3025-7263 | Endereço Rua Engenheiro Rebouças, 434 | Bairro: Centro | CEP: 85851-190 | Cidade: Foz" +
              "do Iguaçu | Estado: PR",
            alignment: "center",
          },
          {
            text: "RELATÓRIO DURAÇÃO DAS ATIVIDADES",
            fontSize: 16,
            bold: true,
            alignment: "center",
          },
        ],
      };
      content.splice(count, 0, objeto_cabecario);
      count += 1;

      this.listaAtividades.forEach((atividade) => {
        if (atividade.colaborador.length) {
          let tabela_corpo = [];
          let tabela_header = [];

          tabela_corpo.push([
            {
              text: "Atividade: " + atividade.titulo,
              bold: true,
              alignment: "center",
              fontSize: 8,
            },
            {
              text: "Cliente: " + atividade.cliente_nome,
              bold: true,
              alignment: "center",
              fontSize: 8,
            },
            {
              text:
                "Início De: " +
                format(
                  new Date(parseISO(atividade.atividade_inicio)),
                  "dd/MM/yyyy"
                ),
              bold: true,
              alignment: "center",
              fontSize: 8,
            },
            {
              text:
                "Até: " +
                format(
                  new Date(parseISO(atividade.atividade_entrega)),
                  "dd/MM/yyyy"
                ),
              bold: true,
              alignment: "center",
              fontSize: 8,
            },
          ]);

          for (let count = 0; count < 4; count++) {
            let line_header = ["COLABORADOR", "INÍCIO", "FIM", "DURAÇÃO"];
            let obj = {
              text: line_header[count],
              colSpan: 1,
              fillColor: "#607D8B",
              color: "white",
              bold: true,
              fontSize: 8,
              alignment: "center",
            };

            tabela_header.splice(count, 0, obj);
          }
          tabela_corpo.push(tabela_header);
          atividade.colaborador.forEach((colaborador) => {
            let body_line = [
              {
                text: colaborador.nome,
                fontSize: 8,
                fillColor: "",
                alignment: "center",
              },
              {
                text: format(
                  parseISO(colaborador.inicio),
                  "dd/MM/yyyy HH:mm:ss"
                ),
                fontSize: 8,
                fillColor: "",
                alignment: "center",
              },
              {
                text: format(parseISO(colaborador.fim), "dd/MM/yyyy HH:mm:ss"),
                fontSize: 8,
                fillColor: "",
                alignment: "center",
              },
              {
                text: colaborador.total,
                fontSize: 8,
                fillColor: "",
                alignment: "center",
              },
            ];
            //
            tabela_corpo.push(body_line);
          });

          tabela_corpo.push([
            {
              text:
                atividade.atividade_status == 1
                  ? "EM ANDAMENTO"
                  : atividade.atividade_status == 0
                  ? "EM ESPERA"
                  : "",
              fontSize: 8,
              fillColor: "",
              color:
                atividade.atividade_status == 1
                  ? "red"
                  : atividade.atividade_status == 0
                  ? "#F57F17"
                  : "",
              bold: true,
              alignment: "center",
            },
            {
              text: "TOTAL EM HORAS",
              fontSize: 8,
              colSpan: 2,
              fillColor: "",
              bold: true,
              alignment: "right",
            },
            "",
            {
              text: atividade.total,
              fontSize: 8,
              fillColor: "#607D8B",
              color: "white",
              bold: true,
              alignment: "center",
            },
          ]);
          let obj_tempo = {
            margin: [0, 0, 0, 10],
            table: {
              widths: ["*", "*", "*", "*"],
              headerRows: 2,
              body: tabela_corpo,
              dontBreakRows: true,
            },
            layout: {
              hLineWidth: function (i, node) {
                return i <= 1 || i === node.table.body.length ? 1 : 0;
              },
              vLineWidth: function (i, node) {
                return i === 0 || i === node.table.widths.length ? 0 : 1;
              },
              hLineColor: function (i, node) {
                return i === 0 || i === node.table.body.length
                  ? "#CFD8DC"
                  : "#CFD8DC";
              },
              vLineColor: function (i, node) {
                return i === 0 || i === node.table.widths.length
                  ? "#CFD8DC"
                  : "#CFD8DC";
              },
              fillColor: function (rowIndex) {
                return rowIndex > 0 && rowIndex % 2 === 0 ? "#E0E0E0" : null;
              },
            },
          };

          content.splice(count, 0, obj_tempo);
          count += 1;
        }
      });

      let total_tempo_total = {
        columns: [
          {
            text: "TOTAL HORAS: " + this.soma_totais[0].total,
            fontSize: 8,

            bold: true,
            alignment: "right",
          },
        ],
      };

      count += 1;
      content.splice(count, 0, total_tempo_total);

      return content;
    },

    async gerarpdf() {
      try {
        this.loading = true;
        this.$Progress.start();

        await pdfGenerator()
          .then((module) => {
            let generate = module.generatePdf;
            generate(this.pdfContent()).then((response) => {
              this.pdfData = response;
              this.pdfDialog = true;
            });
          })
          .finally(() => {
            this.loading = false;
            this.$Progress.finish();
          });
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$Progress.fail();
      }
    },
  },
  async mounted() {
    await this.getClientes();
    await this.getAtividades(`?data=${this.mesAtual}`);
  },
};
</script>

<style lang="scss"></style>
